<template>
  <div class="gamelist-row-head">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'gamelist-row-head'
}
</script>

<style lang="css" scoped>
</style>
